@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    font-family: "Poppins", sans-serif;
    font-style: normal;
}

.button-custom {
    font-weight: bold;
    letter-spacing: 0.5px;
    transition: all 0.3s ease;
}

.button-android {
    background-color: #3ddc84; /* Android yeşili */
    color: white;
    border: none;
}

.button-ios {
    background-color: #000000; /* iOS siyahı */
    color: white;
    border: none;
}

.button-custom:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.banner-background {
    position: relative;
    background-image: url('assets/arkaplan.png'); /* Arka plan resminizi buraya ekleyin */
    background-size: cover;
    background-position: center;
    height: 200px; /* Banner yüksekliğini isteğinize göre ayarlayın */
}



.banner-dark-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.31); /* Karartma efektinin opaklığını buradan ayarlayın */
    display: flex;
    align-items: center; /* İçeriği dikey olarak ortalar */
    justify-content: center; /* İçeriği yatay olarak ortalar */
}

.banner-content h1 {
    color: white;
    text-align: center;
}

/* Tüm kartların aynı yükseklikte olmasını sağlayacak CSS */
.custom-col {
    display: flex;
    flex: 1;
}

.custom-card {
    width: 100%; /* Kartın container'ını doldurmasını sağlar */
    display: flex;
    flex-direction: column; /* İçerikleri dikey olarak sıralar */
}

.custom-card-body {
    flex-grow: 1; /* Kart içeriğinin mevcut alanı doldurmasını sağlar */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Başlık ve açıklamayı yukarı ve aşağıya yaslar */
}

/* QuizContainer.css */
.quiz-container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.question-text {
    font-size: 18px;
    margin-bottom: 20px;
}

.options-list {
    list-style-type: none;
    padding: 0;
}

.options-list li {
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
    transition: background-color 0.3s ease;
    color: b;
}

.options-list li:hover {
    background-color: #efefef;
}

.selected-answer {
    background-color: #ddd;
}

.result-container {
    text-align: center;
}

/* Butonlar ve diğer elementler için ek stil tanımlamaları */
.cevapalani {
    padding: 10px 15px;
    color: #000000;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid rgba(0, 0, 0, 0.39);
}

.button:hover {
    background-color: #0056b3;
}

.button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.correct {
    color: green;
}

.incorrect {
    color: red;
}

.progress-bar .progress {
    height: 20px;
    background-color: blue;  /* Progres bar rengi */
    transition: width 1s ease-in-out;
}





.quiz-container {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    padding: 20px;
    margin-top: 20px;
}

.button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #0056b3;
}

.button.secondary {
    background-color: #6c757d;
}

.button.secondary:hover {
    background-color: #5a6268;
}

.result {
    margin-top: 20px;
    padding: 20px;
    background-color: #f8f9fa;
    border-left: 5px solid #4CAF50;
}

.result p {
    margin: 10px 0;
}



.sonrakibutton{
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
}
.progressalani {
    width: 100%;
    background-color: #f0f0f0;
    border-radius: 5px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    height: 30px;
}

.progress {
    height: 30px;
    background-color: #4CAF50;
    border-radius: 4px;
    text-align: center;
    line-height: 30px; /* Yükseklik ile aynı değer */
    color: white;
    font-size: 25px;

}
/*Banner İçin*/
.banner-content {
    position: relative;
    overflow: hidden;
}

.animated-text {
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    animation: move 10s linear infinite; /* Hareketin süresi ve türü buradan ayarlanabilir */
}

@keyframes move {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}

.moving-text {
    display: inline-block;
    padding-right: 20px; /* Kelimeler arasındaki boşluğu ayarlayabilirsiniz */
}

/*buttonlar için*/
.btn-sade {
    background-color: white;
    color: #000;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 10px;
    border: 1.5px solid rgba(30, 30, 30, 0.83);
    transition: all 0.3s ease;
    gap: 8px;
}

.btn-sade:hover {
    background-color: #f0f0f0;
    border-color: #007bff;
    color: #007bff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.btn-sade i {
    font-size: 18px;
    transition: transform 0.2s;
}

.btn-sade:hover i {
    transform: translateX(5px);
}

.card {
    border-radius: 12px;
    transition: all 0.3s ease;
}

.card:hover {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
}

.card-body img {
    border-radius: 8px;
    transition: all 0.3s ease;
}

.card-body img:hover {
    transform: scale(1.05);
}

/*SSs Cart Alanı*/
.sss-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    transition: box-shadow 0.3s ease;
}

.sss-card:hover {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.sss-card-header {
    background-color: #ffffff;
    padding: 16px;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s ease;
}

.sss-card-header:hover {
    background-color: #f5f9ff;
}

.sss-card-body {
    overflow: hidden;
    transition: all 0.5s ease;
    padding: 0 16px;
}

.sss-card-body p {
    margin: 0;
    padding: 8px 0;
    color: #000000;
}

/*quiz alanı*/
button.disabled-custom {
    background-color: #0d6efd; /* Orijinal mavi rengi koru */
    color: white;
    opacity: 1; /* Disabled modundaki soluk görünümü kaldır */
    cursor: not-allowed; /* Tıklanamayan simge */
    border: 1px solid #0a58ca; /* Kenar rengini sabitle */
}

button.disabled-custom:hover {
    background-color: #0b5ed7; /* Hover efekti de şık olsun */
    color: white;
}